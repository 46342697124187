export function formatDate(dates) {
  const myDates = []
  dates.map(({ node }) => {
    myDates.push({
      title: node.summary,
      date: node.start,
      location: node.location,
      description: node.description,
    })
    return true
  })
  return myDates
}
