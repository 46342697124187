import React from "react"
import { Link } from "gatsby"

const colors = {
  themeOne: {
    rio: "#a2b9bc",
    viff: "#6b5b95",
    pac: "#878f99",
  },
  themeTwo: {
    rio: "#99BCBC",
    viff: "#f5e1ee",
    pac: "#580000",
    fifth: "khaki",
    tinsel: "dimgray",
    scotia: "olive",
  },
}

const CalendarLegend = () => (
  <div style={{ marginBottom: 25, whiteSpace: `wrap`, width: `auto` }}>
    <Link to="/about">
      <span
        style={{
          backgroundColor: colors.themeTwo.viff,
          color: "#444",
          padding: 5,
          marginRight: 10,
          borderRadius: 5,
          marginTop: 10,
        }}
      >
        Vancity Theatre
      </span>
    </Link>
    <Link to="/about">
      <span
        style={{
          backgroundColor: colors.themeTwo.pac,
          color: "#ffffff",
          padding: 5,
          borderRadius: 5,
          marginTop: 10,
        }}
      >
        The Cinematheque
      </span>
    </Link>
    <Link to="/about">
      <span
        style={{
          backgroundColor: colors.themeTwo.rio,
          color: "white",
          padding: 5,
          marginLeft: 10,
          marginTop: 10,
          borderRadius: 5,
          display: `inline-block`,
          whiteSpace: `normal`,
        }}
      >
        The Rio
      </span>
    </Link>
    <Link to="/about">
      <span
        style={{
          backgroundColor: colors.themeTwo.tinsel,
          color: "white",
          padding: 5,
          marginLeft: 10,
          marginTop: 10,
          borderRadius: 5,
          display: `inline-block`,
          whiteSpace: `normal`,
        }}
      >
        International Village
      </span>
    </Link>
    <Link to="/about">
      <span
        style={{
          backgroundColor: colors.themeTwo.scotia,
          color: "white",
          padding: 5,
          marginLeft: 10,
          marginTop: 10,
          borderRadius: 5,
          display: `inline-block`,
          whiteSpace: `normal`,
        }}
      >
        Scotiabank Theatre
      </span>
    </Link>
  </div>
)

export default CalendarLegend
